import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../../../../layouts/dashboard';
// components
import LoadingScreen from '../../../../components/LoadingScreen';
// hooks 
import useAuth from '../../../../hooks/useAuth';
// default routes
import defaultRouter from '../../../defaultRouter';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={
      pathname.includes('/dashboard') ||
      pathname.includes('/report')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export function Router() {
  const { user, username, userRole, userDepartment, userBranch, userRoutes } = useAuth();

  return useRoutes([
    ...defaultRouter({ defaultRoute: "/home", user, username, userRole, userDepartment, userBranch, userRoutes }),

    {
      path: 'training',
      element: <DashboardLayout />,
      children: [
        { path: 'officer', element: <Officer /> },
      ]
    },
    {
      path: 'report',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/report/okr" replace />, index: true },

        {
          path: 'performance',
          children: [
            { element: <Navigate to="/report/performance/gerencial" replace />, index: true },
            {
              path: 'gerencial',
              children: [
                { element: <Navigate to="/report/performance/gerencial/advisor" replace />, index: true },
                { path: 'advisor', element: <Gerencial /> },
              ]
            },
            { path: 'bases', element: <Bases /> },
            { path: 'receita', element: <Receita /> },
            {
              path: 'metas',
              children: [
                { element: <Navigate to="/report/performance/metas/advisor" replace />, index: true },
                { path: 'advisor', element: <Metas /> },
              ]
            },
            {
              path: 'corretagem',
              children: [
                { element: <Navigate to="/report/performance/corretagem/advisor" replace />, index: true },
                { path: 'advisor', element: <Corretagem /> },
              ]
            },
            { path: 'pan', element: <PAN /> },
          ],
        },

        {
          path: 'oportunidades',
          children: [
            { element: <Navigate to="/report/oportunidades/ativacoes" replace />, index: true },
            {
              path: 'ativacoes',
              children: [
                { element: <Navigate to="/report/oportunidades/ativacoes/advisor" replace />, index: true },
                { path: 'advisor', element: <Ativacoes /> },
              ]
            },
          ],
        },

        {
          path: 'ranking',
          children: [
            { element: <Navigate to="/report/ranking/follow-up" replace />, index: true },
            {
              path: 'follow-up',
              children: [
                { element: <Navigate to="/report/ranking/follow-up/advisor" replace />, index: true },
                { path: 'advisor', element: <Ranking /> },
              ]
            },
          ],
        },

        {
          path: 'esforco',
          children: [
            { element: <Navigate to="/report/esforco/crmadvisor" replace />, index: true },
            { path: 'crmadvisor', element: <CRMAdvisor /> }
          ],
        },

        {
          path: 'campanhas',
          children: [
            { element: <Navigate to="/report/campanhas/crm" replace />, index: true },
            { path: 'crm', element: <CRM /> },
          ],
        },

        {
          path: 'reunioes',
          children: [
            { element: <Navigate to="/report/reunioes/n3" replace />, index: true },
            { path: 'n3', element: <N3 /> },
            { path: 'newN3', element: <NewN3 />, index: true },
          ],
        },

      ],
    },
  ]);
}

// REPORT
const N3 = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/reunioes/N3')));
const NewN3 = Loadable(lazy(() => import('../../../../pages/VI/ASSES/LEADER/power-bi/reunioes/NewN3')));
const PAN = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/performance/PAN')));
const Bases = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/performance/Bases')));
const Receita = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/performance/Receita')));
const Metas = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/performance/MetasAdvisor')));
const Ranking = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/ranking/RankingAdvisor')));
const Gerencial = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/performance/GerencialAdvisor')));
const Corretagem = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/performance/CorretagemAdvisor')));
const Ativacoes = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/oportunidades/AtivacoesAdvisor')));
const CRM = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/campaign/CRM')));
const CRMAdvisor = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/esforco/CRMAdvisor')));
// const Ativacao300kConsulta = Loadable(lazy(() => import('../../../../pages/VI/ASSES/general/power-bi/campaign/ativacao300k/Consulta')));

const Officer = Loadable(lazy(() => import('../../../../pages/general/tools/training/Officer')));
